export const EVENT_TYPES = {
  "1": "Registration",
  "2": "Login",
  "3": "Password recovery requested",
  "4": "Banned",
  "5": "Unbanned",
  "6": "Profile changed",
  "7": "Notifications Enabled",
  "8": "Notifications Disabled",
  "9": "Notification settings changed",
  "10": "2FA Enabled",
  "11": "2FA Disabled",
  "12": "Password changed",
  "13": "Login changed",
  "14": "Email changed",
  "15": "Password recovered",
  "16": "Access changed",
  "17": "Money transfer to account",
  "18": "Whitelist changed",
  "19": "Payed features enabled",
  "20": "Payed features disabled",
  "21": "Referral registration",
  "22": "Config applied",
  "23": "Worker deleted",
  "24": "Worker transferred to farm",
  "25": "Worker transferred from farm",
  "26": "Farm created",
  "27": "Farm deleted",
  "28": "Worker created",
  "29": "Farm transferred",
  "30": "Farm payer changed",
  "31": "Farm locked",
  "32": "Farm unlocked",
  "33": "Command sent",
  "34": "Account closed",
  "35": "Charge on pool enabled",
  "36": "Charge on pool disabled",
  "37": "Email confirmed",
};

const reduceFunc = (obj, key) => {
  obj[key] = EVENT_TYPES[key];
  return obj;
};

export const USER_EVENT_TYPES = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "17",
  "18",
  "21",
  "26",
  "27",
  "34",
  "37",
].reduce(reduceFunc, {});

export const FARM_EVENT_TYPES = [
  "16",
  "19",
  "20",
  "22",
  "23",
  "24",
  "25",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "35",
  "36",
].reduce(reduceFunc, {});

export const TRANSACTION_TYPES = {
  "1": "Deposit",
  "2": "Daily service usage",
  "3": "Referral Reward",
  "4": "Gift of fate",
  "5": "Referral Withdrawal",
  "6": "Sent to User",
  "7": "Received from User",
  "8": "Referral Exchange",
  "9": "Promo code",
  "10": "Sent to Farm",
  "11": "Received from Farm",
  "12": "Deposit from referral balance",
  "13": "Referral Reward (from worker)",
  "14": "Deposit Bonus",
  "15": "Admin Withdrawal",
  "16": "Pool fee",
};

export const PLATFORMS = {
  "1": "Rig",
  "2": "ASIC",
};

export const ACL_ROLES = {
  "monitor": "Monitor",
  "tech": "Tech",
  "rocket": "Rocket",
  "advanced": "Advanced",
  "full": "Full Access",
};

export const API_KEY_SOURCE_TYPES = {
  "exchange": "Exchange",
  "pool": "Pool",
};

export const TAG_TYPES = {
  "1": "Worker",
  "2": "Farm",
};

export const MESSAGE_TYPES = {
  "info": "Info",
  "success": "Success",
  "warning": "Warning",
  "danger": "Danger",
};

export const DEPOSIT_WALLET_PROVIDERS = {
  "hive": "Hive",
  "coinpayments": "Coinpayments",
};

export const BILLING_TYPES = {
  "11": "Rig General",
  "12": "Rig Hiveon",
  "13": "Rig Non-Hiveon",
  "14": "CPU Rig",

  "21": "ASIC General",
  "22": "ASIC Hiveon",
  "23": "ASIC Promo",
};
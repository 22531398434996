import {AUTH_LOGIN, AUTH_CHECK, AUTH_ERROR, AUTH_LOGOUT, AUTH_GET_PERMISSIONS} from 'react-admin';
import dataProvider, {GET, POST, HttpError} from 'providers/myRestProvider';
import Cookies from "js-cookie";

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const {username, password, twofa_code, remember} = params;

    return dataProvider(POST, 'auth/login', {data: {login: username, password, twofa_code, remember}})
      .then(({access_token, user}) => {
        localStorage.setItem('token', access_token);
        setCurrentUser(user);
      });
  }

  else if (type === AUTH_CHECK) {
    console.log('AUTH_CHECK', params);

    if (!localStorage.getItem('token')) {
      return Promise.reject();
    }

    return fetchUser()
  }

  else if (type === AUTH_ERROR) {
    const {status} = params;
    console.log('AUTH_ERROR', status, params);

    if (status !== 401 && status !== 403) {
      // we're not interested in other errors
      return Promise.resolve();
    }

    if (!localStorage.getItem('token')) {
      return Promise.reject();
    }

    return Promise.resolve();
  }

  else if (type === AUTH_LOGOUT) {
    console.log('AUTH_LOGOUT', params);

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return Promise.resolve();
  }

  else if (type === AUTH_GET_PERMISSIONS) {
    console.log('AUTH_GET_PERMISSIONS', params);

    const user = getCurrentUser();
    if (!user) {
      return Promise.reject();
    }

    const {su = false, permissions = []} = user;
    console.log('su=', su, 'permissions=', permissions);
    const p = new Permissions(su, permissions);
    return Promise.resolve(p);
  }

  return Promise.resolve();
}

// user cache
let currentUser = null;

/**
 * Set current user to storage
 * @param {object} user
 */
function setCurrentUser(user) {
  currentUser = user || {};
  localStorage.setItem('user', JSON.stringify(currentUser));
}

/**
 * Get current user from storage
 * @return {object}
 */
function getCurrentUser() {
  if (currentUser) {
    return currentUser;
  }
  try {
    const userJson = localStorage.getItem('user');
    if (!userJson) {
      return null;
    }
    return currentUser = JSON.parse(userJson);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function fetchUser() {
  return dataProvider(GET, 'auth/check')
    .then(response => {
      const {user} = response || {};
      if (user) {
        setCurrentUser(user);
      }
      return Promise.resolve();
    })
    .catch(e => {
      if (e instanceof HttpError && e.status === 401) {
        // Unauthenticated error
        console.error('auth/check failed', e);
        return Promise.reject();
      }
      // maybe server error or something other not related to auth
      return Promise.resolve();
    });
}

/**
 * Try login using cookies from main website
 */
export function TryLoginByMainCookie() {
  const mainCookieKey = 'jwtt';

  if (!localStorage.getItem('token') && Cookies.get(mainCookieKey)) {
    localStorage.setItem('token', Cookies.get(mainCookieKey))
    fetchUser().then(() => {
      document.location.replace('/');
    }).catch(() => {
      localStorage.removeItem('token')
    });
  }
}

/**
 * This class is a wrapper on permissions object.
 * All property GETs are intercepted with get() method.
 * @see https://stackoverflow.com/a/43323115
 */
class Permissions {
  constructor(su, permissions) {
    this.su = su;
    // convert array of string to object keys
    this.permissions = (permissions || []).reduce((obj, val) => {obj[val] = true; return obj;}, {});
    return new Proxy(this, this);
  }

  get(target, prop) {
    // if super admin - always return true
    // otherwise check permission key
    return this.su || this._checkPermission(prop);
  }

  /**
   *
   * @param {string} perm
   * @return {boolean}
   * @private
   */
  _checkPermission(perm) {
    if (!perm.endsWith('.*')) {
      return !!this.permissions[perm];
    }

    const match = perm.substr(0, perm.length - 2);

    return !!Object.keys(this.permissions).find(key => {
      return key === match || key.startsWith(`${match}.`);
    });
  }
}

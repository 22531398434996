import jsonServerProvider from "ra-data-json-server"
import myRestProvider from "./myRestProvider"

/**
 * Multiple provider
 * @see https://stackoverflow.com/questions/50724915/is-it-possible-to-have-multiple-dataproviders-in-react-admin
 */


const dataProviders = [
	{dataProvider: jsonServerProvider('http://jsonplaceholder.typicode.com'), resources: ['posts']},

	// the rest resources
	{dataProvider: myRestProvider, resources: '*'},
];

export default (type, resource, params) => {
	const dataProvider = dataProviders.find(dp => dp.resources.includes(resource) || dp.resources === '*');
	return dataProvider.dataProvider(type, resource, params);
}
import React from "react";
import {
  DateTimeInput,
  LongTextInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import {withStyles} from '@material-ui/core/styles';
import {makeAsyncValidate} from "common/forms/Utils";
import {MESSAGE_TYPES} from "constants/types";
import {userOptionRenderer} from "fields/UserAliasField";

const allTypes = Object.keys(MESSAGE_TYPES).map(id => ({id: id, name: MESSAGE_TYPES[id]}));

class AnnouncementForm extends React.Component {
  render() {
    const {action, classes: c, ...props} = this.props;

    return <SimpleForm {...props} asyncValidate={makeAsyncValidate(action)}>
      <TextInput source="title" fullWidth={true}/>
      <LongTextInput source="message"/>
      <SelectInput source="type" choices={allTypes}/>
      <TextInput source="url" label='Details URL' fullWidth={true}/>
      <DateTimeInput source="start_at"/>
      <DateTimeInput source="end_at"/>
      <BooleanInput source="active"/>
      <ReferenceInput label="User" source="user_id" reference="users" allowEmpty={true}>
        <AutocompleteInput optionText={userOptionRenderer}/>
      </ReferenceInput>
    </SimpleForm>
  }
}

const styles = theme => ({
  wideInput: {
    width: '100%'
  }
});

AnnouncementForm = withStyles(styles)(AnnouncementForm);

export default AnnouncementForm;

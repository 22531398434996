import React from 'react';
import {List, Datagrid, TextField, Filter, TextInput, ChipField, ReferenceArrayField} from 'react-admin';
import Icon from '@material-ui/icons/Timeline';
import SortedSingleFieldList from "../common/lists/SortedSingleFieldList";

export const AlgosIcon = Icon;

const AlgosFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Name" source="name" alwaysOn />
	</Filter>
);

export const AlgosList = (props) => (
    <List
        title="Algos"
        {...props}
        perPage={100}
        filters={<AlgosFilter/>}
        sort={{ field: 'sort_order', order: 'DESC' }}
        bulkActionButtons={ false }
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="sort_order" />
            <ReferenceArrayField label="Coins" reference="coins" source="coin_ids" sortable={false}>
              <SortedSingleFieldList sortBy="rank">
                <ChipField source="symbol"/>
              </SortedSingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
);

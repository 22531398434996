import { createMuiTheme } from '@material-ui/core/styles';

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  colors: {
    back: '#21252c',
    layout: '#1d1f24',
    rounding: '#bfbfbf',
    text: '#ccc',
    controlPrimary: '#f7a700',
    red: '#b53f3f',
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: '#21252c',
        color: '#ccc',
      },
    },
    BulkActionsToolbar: {
      color: 'red'
    },
    MuiButton: {
      flat: {
        color: '#f7a700',
      },
      containedPrimary: {
        color: '#21252c',
        backgroundColor: '#d4d4d4',
        border: '1px solid #909090',
        ['&:hover']: { //eslint-disable-line no-useless-computed-key
          color: '#21252c',
          backgroundColor: '#bfbfbf',
          border: '1px solid #aaa',
        }
      }
    },
    MuiIconButton: {
      colorPrimary: {
        color: '#f7a700 !important',
      },
    },
    MuiTypography: {
      headline: {
        color: '#ccc',/*eslint-disable-line no-useless-computed-key*/
      }
    },
    MuiCardContent: {
      root: {
        color: '#ccc',
      }
    },
    MuiTab: {
      wrapper: {
        color: '#ccc',
      },
      root: {
        minWidth: '50px !important',
      },
      labelContainer: {
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
      }
    },
    MuiTableCell: {
      root: {
        ['& a, & a span']: { //eslint-disable-line no-useless-computed-key
          color: '#f7a700',
        }
      }
    },
    MuiFormControl: {
      root: {
        ['& a, & a span']: { //eslint-disable-line no-useless-computed-key
          color: '#f7a700',
        }
      }
    },
    MuiTabs: {
      scroller: {
        ['& span[class^="TabIndicator-"]']: { //eslint-disable-line no-useless-computed-key
          backgroundColor: '#b53f3f',
        }
      }
    },
    MuiSwitch: {
      bar: {
        backgroundColor: '#f7a700 !important',
      },
      iconChecked: {
        color: '#f7a700',
      }
    },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: '#f7a700'
      }
    }
  },
});

export default darkTheme;

import { CREATE, DELETE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE, } from 'react-admin';
import { stringify } from 'query-string';
import { CONFIG } from '../config';
import { diff } from 'deep-object-diff';
import _ from 'lodash';

/* Custom types */
export const GET = 'GET';
export const POST = 'POST';

export const VALIDATE_FOR_CREATE = 'VALIDATE_FOR_CREATE';
export const VALIDATE_FOR_UPDATE = 'VALIDATE_FOR_UPDATE';

/**
 *
 * @param {object} params Data provider params
 * @return {object} Data to send to server
 */
const getChanged = ({data, previousData}) => {
  // find what is changed using deep diff function
  // and then send root-level keys to server
  if (!previousData) {
    return data;
  }
  const delta = diff(previousData, data);
  delete delta.updated_at;
  return _.pick(data, Object.keys(delta));
};

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const createHttpRequest = (type, resource, params = {}) => {
    console.log(type, resource, params);
    let query;
    switch (type) {
        /* Custom types */
        case GET:
          return {
            url: resource
          };
        case POST:
          return {
            url: resource,
            options: {method: 'POST', body: JSON.stringify(params.data)},
          };

        /* React-admin's types */
        case GET_LIST: {
            // console.log(params)
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                // sort: JSON.stringify([field, order]),
                // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                page: page,
                per_page: perPage,
                sort_field: field,
                sort_order: order,
                filter: JSON.stringify(params.filter),
            };
            return { url: `${resource}?${stringify(query)}` };
        }
        case GET_ONE:
            return { url: `${resource}/${params.id}` };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            return { url: `${resource}?${stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
            };
            return { url: `${resource}?${stringify(query)}` };
        }
      case UPDATE:
      case VALIDATE_FOR_UPDATE:
            const updateData = getChanged(params);
            query = type === VALIDATE_FOR_UPDATE ? '?only_validate=1' : '';
            return {
                url: `${resource}/${params.id}${query}`,
                options: { method: 'PATCH', body: JSON.stringify(updateData) },
            };
        case CREATE:
        case VALIDATE_FOR_CREATE:
            query = type === VALIDATE_FOR_CREATE ? '?only_validate=1' : '';
            return {
                url: `${resource}${query}`,
                options: { method: 'POST', body: JSON.stringify(params.data) },
            };
        case DELETE:
            return {
                url: `${resource}/${params.id}`,
                options: { method: 'DELETE' },
            };
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const parseHttpResponse = (response, type, resource, params) => {
  const { json } = response;
  switch (type) {
    /* Custom types */
    case GET:
    case POST:
      return json || {};

    /* React-admin's types */
    case GET_LIST:
      return {
        data: json.data,
        total: json.pagination.total,
      };
    case GET_MANY:
      return {
        data: json.data,
      };
    case  GET_MANY_REFERENCE:
      return {
        data: json.data,
        total: json.pagination.total,
      };
    case CREATE:
      return { data: { ...params.data, id: json.id } };
    case DELETE:
      return { data: { id: null } };
    default:
      return { data: json || {} };
  }
};

export class HttpError extends Error {
  /**
   * @param {string} message Error message
   * @param {int} status Response code
   * @param {object} data Response data
   */
  constructor(message, status, data = null) {
    super(message);
    this.status = status;
    this.data = data;
  }
}

export function fetchApi(url, options, prependApiUrl = true) {
  options = {...options};

  if (!options.headers) {
    options.headers = new Headers();
  }

  if (!options.headers.has('Content-Type')) {
    options.headers.set('Content-Type', 'application/json');
  }

  if (!options.headers.has('Accept')) {
    options.headers.set('Accept', 'application/json');
  }

  const token = localStorage.getItem('token');
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  if (options.body && typeof(options.body) === 'object') {
    options.body = JSON.stringify(options.body);
  }

  if (prependApiUrl) {
    url = `${CONFIG.apiUrl}/${url}`
  }

  const request = new Request(url, options);

  const processResponse = (response, data) => {
    const method = options.method || 'GET';
    console.log(`${method} ${url} ${response.status}`, 'request:', options.body ? JSON.parse(options.body) : null, 'response:', data);

    if (response.status === 422) {
      let {message, errors} = data || {};
      Object.keys(errors).forEach(key => {
        message += ' ' + errors[key].join(' ')
      });
      throw new HttpError(message || 'Server error', response.status, data);
    }
    if (!response.ok) {
      const {message} = data || {};
      throw new HttpError(message || `Error ${response.status}: ${response.statusText}`, response.status, data);
    }

    return data;
  };

  return fetch(request)
    .then(response => {
      return response.text().then(text => {
        const data = text !== '' ? JSON.parse(text) : null;
        return processResponse(response, data);
      });
    });
}

export function createProvider(createHttpRequest, parseHttpResponse) {

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Promise} the Promise for response
   */
  return (type, resource, params) => {
    const {url, options} = createHttpRequest(type, resource, params);
    return fetchApi(url, options)
      .then(json => {
        return parseHttpResponse({json}, type, resource, params);
      });
  };
}

export default createProvider(createHttpRequest, parseHttpResponse);

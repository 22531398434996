import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  Edit,
  EditButton,
  DeleteButton,
  Toolbar,
  SaveButton,
  DateField,
  BooleanField,
  ReferenceField,
} from 'react-admin'
import Icon from '@material-ui/icons/Announcement';
import {CustomPagination} from "common/pagination/CustomPagination";
import AnnouncementForm from "components/announcements/Form";
import UserAliasField from "fields/UserAliasField";
import CustomSelectField from "fields/CustomSelectField";
import {MESSAGE_TYPES} from "constants/types";
import UrlField from "fields/UrlField";

const allTypes = Object.keys(MESSAGE_TYPES).map(id => ({id: id, name: MESSAGE_TYPES[id]}));

export const AnnouncementsIcon = Icon;

export const AnnouncementsFilter = (props) => (
  <Filter {...props}>
    <TextInput label='ID' source='id' alwaysOn/>
    <TextInput label='Name' source='name' alwaysOn/>
    <TextInput label='Message' source='message' alwaysOn/>
  </Filter>
);

export const AnnouncementsGrid = ({consumer, classes: c, permissions, ...props}) => {
  return <Datagrid {...props}>
    <TextField source='id'/>
    <TextField source='title'/>
    <TextField source='message'/>
    <CustomSelectField source='type' choices={allTypes}/>
    <UrlField source='url'/>
    <DateField source='start_at' showTime locales='uk-UA'/>
    <DateField source='end_at' showTime locales='uk-UA'/>
    <BooleanField source='active'/>
    <ReferenceField label='User' source='user_id' reference='users' allowEmpty={true}>
      <UserAliasField/>
    </ReferenceField>
    {permissions && permissions['admin.announcements.manage'] && <EditButton/>}
    {permissions && permissions['admin.announcements.manage'] && <DeleteButton/>}
  </Datagrid>
};

export const AnnouncementsList = ({permissions, ...props}) => {
  return <List
    title="Announcements"
    {...props}
    sort={{field: 'id', order: 'ASC'}}
    pagination={<CustomPagination/>}
    perPage={50}
    filters={<AnnouncementsFilter/>}
    bulkActionButtons={false}
  >
    <AnnouncementsGrid permissions={permissions}/>
  </List>
};

const AnnouncementCreateToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      label="Save"
      redirect="list"
      submitOnEnter={true}
    />
    <SaveButton
      label="Save and add new"
      redirect={false}
      submitOnEnter={false}
      variant="flat"
    />
  </Toolbar>
);

export const AnnouncementCreate = (props) => (
  <Create {...props}>
    <AnnouncementForm action='create' toolbar={<AnnouncementCreateToolbar/>}/>
  </Create>
);

const AnnouncementEditTitle = ({record}) => {
  return <span>Edit Announcement {record ? `"#${record.id}"` : ''}</span>;
};

export const AnnouncementEdit = (props) => (
  <Edit {...props} title={<AnnouncementEditTitle/>}>
    <AnnouncementForm action='edit'/>
  </Edit>
);
